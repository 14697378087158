<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-4">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="배출수명"
                    name="envWaterMstDiswaterName"
                    v-model="data.envWaterMstDiswaterName">
                  </c-text>
                </div>
                <div class="col-4">
                  <c-text
                    :editable="editable"
                    label="비고"
                    name="remark"
                    v-model="data.remark">
                  </c-text>
                </div>
                <div class="col-4">
                  <c-plant :required="true" :editable="editable" type="edit" name="plantCd" v-model="data.plantCd" />
                </div>
                <div class="col-4">
                  <c-dept type="edit" :editable="editable" label="관리부서" name="deptCd" v-model="data.deptCd" />
                </div>
                <div class="col-4">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    label="사용여부"
                    name="useFlag"
                    v-model="data.useFlag"
                  />
                </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-master03-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envWaterMstDiswaterId: '',
          plantCd: null,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envWaterMstDiswaterId: '',  // 수질배출수 일련번호
        envWaterMstDiswaterName: '',  // 수질배출수명
        remark: '',
        deptCd: '',  // 관리부서 코드
        useFlag: 'Y',  // 사용여부
      },
      saveUrl: transactionConfig.env.water.mst.diswater.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      saveable: false,
      dataeditable: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.water.mst.diswater.get.url;
      this.saveUrl = transactionConfig.env.water.mst.diswater.insert.url;

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envWaterMstDiswaterId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWaterMstDiswaterId: this.popupParam.envWaterMstDiswaterId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
        },);
      }
    },
    saveInfo() {
      if (this.popupParam.envWaterMstDiswaterId) {
        this.saveUrl = transactionConfig.env.water.mst.diswater.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.water.mst.diswater.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envWaterMstDiswaterId = result.data.envWaterMstDiswaterId;
      this.popupParam.plantCd = result.data.plantCd;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>